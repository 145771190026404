import tw from "tailwind-styled-components";
import styled from "styled-components";

import bgImage from "../images/hall04-bs-nosepick-hall04-banks.jpg";

import { GatsbyImage } from "gatsby-plugin-image";

const Container = tw.div`
  container
  px-4
  py-8
  mx-auto
  relative
  overflow-hidden
`;

const Heading = tw.h2`
	mt-8
	mb-10
	text-4xl
	font-heading
	md:text-5xl
`;

const Content = tw.div`
 .gatsby-image-wrapper img{
		rounded-lg
	}
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  img {
    border-radius: 0.5rem;
  }
`;

const WithTrianglesContainer = styled(Container)`
  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: skewX(60deg);
  }

  &:after {
    background-color: #e0eff9;
    left: 100%;
  }
  &:before {
    background-color: #fefdee;
    transform: skewX(135deg);
  }
`;

const WithCutcornerBL = styled.div`
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 70%);
  img {
    border-radius: 0.5rem;
  }
`;
const WithReverseCutcornerBL = styled.div`
  clip-path: polygon(0 0, 100% 0%, 100% 70%, 0% 100%);
  img {
    border-radius: 0.5rem;
  }
`;

const SingleImageContent = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url(${bgImage}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const ContentText = styled.div`
  color: #fff;
  position: fixed;
  bottom: 2%;
  left: 3%;
  font-size: 13px;
  @media all and (min-width: 768px) and (min-height: 600px) {
    font-size: 16px;
    transform: translateY(-30%);
  }

  h1 {
    font-size: 1.25rem;
    @media all and (min-width: 768px) and (min-height: 600px) {
      font-size: 2.225rem;
    }
  }
`;

const UlContact = styled.ul`
  display: flex;
  gap: 0 1rem;
  flex-direction: column;
  @media all and (min-width: 768px) {
    flex-direction: row;
  }
`;

const UlSocial = styled(UlContact)`
  display: flex;
  gap: 0 1rem;
  flex-direction: column;
  li {
    display: flex;
    gap: 0 1rem;
    flex-direction: column;
    @media all and (min-width: 768px) {
      flex-direction: row;
    }
  }
  @media all and (min-width: 768px) {
    flex-direction: row;
  }
`;

const Credit = styled.div`
  position: absolute;
  top: 6px;
  left: 3%;
  color: #fff;
  font-size: 6px;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: top left;
  text-transform: uppercase;
  p {
    white-space: nowrap;
  }
  @media all and (orientation: landscape) {
    left: 1%;
  }

  @media all and (min-width: 768px) and (min-height: 600px) {
    font-size: 8px;
  }
  @media all and (min-width: 1376px) {
    left: calc(3% + 26px);
  }
`;

const StyledILoveH04 = styled.div`
  display: none;
  @media all and (min-width: 560px) and (min-height: 460px) {
    display: block;
    position: absolute;
    z-index: 100;
    right: 3%;
    bottom: 2%;
  }
`;

const DesktopOnly = styled.span`
  display: none;
  @media all and (min-width: 768px) {
    display: initial;
  }
`;

export {
  Container,
  Heading,
  Content,
  StyledGatsbyImage,
  WithTrianglesContainer,
  WithCutcornerBL,
  WithReverseCutcornerBL,
  SingleImageContent,
  ContentText,
  UlContact,
  UlSocial,
  Credit,
  DesktopOnly,
  StyledILoveH04,
};
